<template>
  <div class="after-before-block__content">
    <div
      ref="desc"
      v-if="slide.description"
      class="after-before-block__description"
      :class="{'after-before-block__description--overflow': slide.description.length > 220 && !hideDesc}">
        {{ slide.description }}
    </div>
    <button @click="toggleHide" v-if="!hideDesc && slide.description.length > 220" class="button button--secondary after-before-block__btn button--size-s">Читать полностью</button>
  </div>
</template>

<script setup lang="ts">
import type { ImageItem, ImageSEO } from '~/types/pages';
import { ref } from '#imports';
import './styles.scss';
interface Props {
  slide: {
    image1: ImageItem;
    image2: ImageItem;
    image1Seo: ImageSEO;
    image2Seo: ImageSEO;
    description: string;
  }[];
}

defineProps<Props>();

const hideDesc = ref(false);

const toggleHide = () => {
  hideDesc.value = !hideDesc.value
}


</script>
